// export const staticPath = "http://192.168.1.1";
// export const apiUrl = "http://192.168.1.1:8080";
// export const authUrl = "http://192.168.1.1:8080";


export const staticPath = "https://api.solbaumanec.ru";
export const apiUrl = "https://api.solbaumanec.ru";
export const authUrl = "https://api.solbaumanec.ru";

// export const staticPath = "http://localhost:8080";
// export const apiUrl = "http://localhost:8080";
// export const authUrl = "http://localhost:8080";

// 192.168.1.1:8080
