import SockJS from "sockjs-client";
import Stomp from "stompjs";
import {useEffect} from "react";
import {apiUrl} from "../schema/Environment";


let settersMap = new Map();
let stompClient = null;
let isConnected = null;
let reconnectInterval;

const UseWebSocket = (topic, type, setter, uniqueConnectionId) => {
    useEffect(() => {
        if (!stompClient) {
            connect();
        }
        try {
            clearInterval(reconnectInterval);
        }catch (e){

        }
        reconnectInterval = setInterval(() => {
            if(stompClient && !stompClient.connected)
                isConnected = false;
            if (!isConnected) {
                console.log("Lost connection to web socket")
                connect();
            }
        }, 1000);

        // return () => {
        //     console.log("Destroy Socket")
        //     clearInterval(reconnectInterval);
        // };
    }, [topic, type, setter, stompClient]);

    function connect() {
        let socket = new SockJS(apiUrl + '/socket');
        let initStompClient = Stomp.over(socket);
        initStompClient.connect({}, (frame) => {
            console.log('Connected to socket');
            isConnected = true;
            stompClient = initStompClient;
        });
    }

    setSetter(topic, type, setter, uniqueConnectionId);
}

function setSetter(topic, type, setter, uniqueConnectionId){

    const intervalTimer = setInterval(() => {
        if (stompClient) {
            const setterFunction = (responseJson) => {
                if (responseJson.responseType === type) {
                    setter(responseJson.object);
                }
            }
            let uniqueKey = topic + "_" + type;
            if(uniqueConnectionId)
                uniqueKey = uniqueKey + "_" + uniqueConnectionId;
            if (!settersMap.has(uniqueKey)) {
                settersMap.set(uniqueKey, [setterFunction]);
                stompClient.subscribe(topic, (message) => {
                    var responseJson = JSON.parse(message.body);
                    settersMap.get(uniqueKey).forEach(setterFunc => setterFunc(responseJson));
                });
            }

            clearInterval(intervalTimer);

            return () => {
                stompClient.unsubscribe(topic);
                settersMap.delete(topic);
            };
        }
    },1000);
}

export default UseWebSocket;

