import {useEffect, useRef, useState} from "react";
import axios from "axios";


export const AxiosGet = (url, params, enqueueSnackbar) => {
    const [state, setState] = useState({
        loaded: false,
        error: null,
        data: null,
    });

    const prevParams = useRef();


    const fetchData = async () => {
            try {
                const response = await axios.get(url, { params: params });
                setState({
                    loaded: true,
                    error: null,
                    data: response.data,
                });
            } catch (error) {
                if(error.code === "ERR_NETWORK") {
                    setState({
                        loaded: false,
                        error: "Сервер временно не доступен",
                        data: null,
                    });
                    enqueueSnackbar("Сервер временно не доступен", {variant: 'error'});
                }
                else {
                    setState({
                        loaded: false,
                        error: error.response ? error.response.status : error.status,
                        data: null,
                    });
                    if (error.response !== undefined)
                        enqueueSnackbar(error.response.data.error, {variant: 'error'});
                    else
                        enqueueSnackbar(error.message, {variant: 'error'});
                }
            }
    };

    useEffect(() => {
        if(url === null)
            return;
        if (!prevParams.current || JSON.stringify(prevParams.current) !== JSON.stringify(params)) {
            fetchData();
            prevParams.current = params;
        }
    }, [url, params]);

    return { ...state };
};


export const AxiosGetWithoutState = async (url, params, currentToken) => {

        try {
            const reqHeaders = {
                token: currentToken, // Установка заголовка "token" с заданным значением
            };
            const response = await axios.get(url, {params: params, headers: reqHeaders});
            return {
                loaded: true,
                error: null,
                data: response.data,
            }
        } catch (error) {
            if(error.code === "ERR_NETWORK") {
                return {
                    loaded: true,
                    error: "Сервер временно не доступен",
                    data: null
                }
            }else {
                return {
                    loaded: true,
                    error: error.response ? error.response.data.error : error.message,
                    data: null
                }
            }
        }
};
