import './App.css';
import {SnackbarProvider} from "notistack";
import {ThemeProvider} from "@mui/material";
import { baseTheme } from "./js/schema/theme/BaseTheme";
import Democracy from "./js/democracy/Democracy";
import './css/custom.css';
import './css/CountdownTimer.css';

function App() {
    console.log("App");
  // const {currentToken, setToken} = UseToken();
  //
  // if (currentToken) {
  //     try {
  //         const decodedToken = JSON.parse(atob(currentToken.split('.')[1]));
  //         const currentTime = Math.floor(Date.now() / 1000);
  //
  //         if (decodedToken.exp < currentTime) {
  //             setToken()
  //         }
  //     }catch (e){
  //         setToken();
  //     }
  // }

  // if(currentToken) {
    return (
        <SnackbarProvider maxSnack={3} anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
              <ThemeProvider theme={baseTheme}>
                {/*<TokenContext.Provider value={[currentToken, setToken]}>*/}
                  <Democracy/>
                {/*</TokenContext.Provider>*/}
              </ThemeProvider>
        </SnackbarProvider>
    );
  // }
  // else{
  //   return (
  //         <SnackbarProvider maxSnack={3} anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
  //             <ThemeProvider theme={baseTheme}>
  //                 <TokenContext.Provider value={[currentToken, setToken]}>
  //                     <Login/>
  //                 </TokenContext.Provider>
  //             </ThemeProvider>
  //         </SnackbarProvider>
  //   )
  // }
}

export default App;
