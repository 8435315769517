import React, {useContext, useEffect, useState} from "react";
import {useSnackbar} from "notistack";
import {apiUrl, staticPath} from "../schema/Environment";
import {AxiosGetWithoutState} from "../axios/AxiosGet";
import {Box, Button, Grid, Typography, useMediaQuery} from "@mui/material";
import {useTheme} from "@mui/system";

import TransparentImage from "../../images/backgrounds/transparent.png";
import PoliticRight from "../../images/backgrounds/politic_right.png";
import PoliticLeft from "../../images/backgrounds/politic_left.png";
import MainPopover from "../../images/backgrounds/democracy_main_bg.svg";
import {AxiosPostWithoutState} from "../axios/AxiosPost";
import DemocracySanctions from "./DemocracySanctions";
import UseToken from "../auth/UseToken";
import UseWebSocket from "../socket/UseWebSocket";
import {SelectValueFullWidth} from "../components/inputs/SelectValue";


export default function Democracy() {
    const [teamId, setTeamId] = useState();
    const {enqueueSnackbar} = useSnackbar()
    const {currentToken, setToken} = UseToken();
    const [controlsData, setControlsData] = useState(
        {
            status: "DEVELOP",

            roundDescriptionIsShow: false,
            ratingIsShow: false,
            questionIsShow: false,
            answerIsShow: false,
            mediaIsShow: false,

            currentRound: null,
            currentQuestion: null,

            mediaIdToShow: null,
            timeIsActive: null,

            roundSequence: 1,
            questionSequence: 1,
            teamSequence: 1,
            teamList: []
        }
    );
    UseWebSocket("/democracy", "CONTROLS_DATA", update);


    function update (controls) {
        setControlsData((prevControlsData) => {
            if (controls === null) {
                return null;
            } else {
                return {
                    status: controls.status,
                    roundDescriptionIsShow: controls.roundDescriptionIsShow,
                    ratingIsShow: controls.ratingIsShow,
                    questionIsShow: controls.questionIsShow,
                    answerIsShow: controls.answerIsShow,
                    mediaIsShow: controls.mediaIsShow,
                    currentRound: controls.currentRound,
                    currentQuestion: controls.currentQuestion,
                    mediaIdToShow: controls.mediaIdToShow,
                    timeIsActive: controls.timeIsActive,
                    roundSequence: controls.roundSequence,
                    questionSequence: controls.questionSequence,
                    teamSequence: controls.teamSequence,
                    teamList: controls.teamList,
                };
            }
        });
    }

    useEffect(() => {
        AxiosGetWithoutState(apiUrl + "/democracy/user/connect", null, currentToken).then(({loaded, error, data}) => {
            if(error){
                update(null)
            }
            else {
                update(data);
            }
        }).catch(error => {
            enqueueSnackbar("Произошла ошибка при подключении к игре", {variant: "error"});
            console.log("Произошла ошибка при подключении к игре: \n" + error);
        });
    },[currentToken])

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        let teamIdFromUrl = urlParams.get('teamId');
        if(!teamIdFromUrl)
            teamIdFromUrl = urlParams.get('teamid');

        if (!teamIdFromUrl) {
            enqueueSnackbar("Произошла ошибка при подключении к игре", {variant: "error"});
        }
        else{
            setTeamId(teamIdFromUrl)
        }
    }, [currentToken]);

    const filteredTeam = controlsData?.teamList?.filter(t => t.id === parseInt(teamId));
    const currentTeam = filteredTeam?.[0];


    return (
        <DemocracyContent team={currentTeam} controlsData={controlsData}/>
    )
}

function DemocracySanctionWrapper({teamId, teamList}) {
    const theme = useTheme();
    const [selectedSanction, setSelectedSanction] = useState();

    const handleSanctionSelect = (sanction) => {
        setSelectedSanction(sanction);
    }
    return (
        <>
            <Box height={"100%"} flexGrow={"1"}>
                <DemocracySanctions handleSanctionSelect={handleSanctionSelect}/>
            </Box>


            <Box className={"flex-col"} height={"100%"} width={"24rem"}>
                <Box className={"m-b-1 customBg"} width={"24rem"} marginTop={"-8px"}  marginLeft={"-8px"} boxSizing={"border-box"} padding={"9px"} sx={{borderRadius: "0 0.5rem 0.5rem 0"}}>
                    <Typography variant="h3" color={theme.palette.text.contrast} textAlign={"center"}>Покупка</Typography>
                </Box>

                {selectedSanction ?
                    <SanctionBuy selectedSanction={selectedSanction} teamList={teamList.filter(t => t.id !== parseInt(teamId))} teamId={teamId}/>
                    :
                    <Box className={"b-r-0_5 m-l-1 p-0_5 flex-grow flex-col-vcenter-hcenter"} border={"0.2rem solid " + theme.palette.primary.dark} >
                        <Typography variant="h4" color={theme.palette.primary.dark} textAlign={"center"}>Выберите санкцию для покупки</Typography>
                    </Box>
                }
            </Box>
        </>
    );
}

function DemocracyContent({team, controlsData}) {
    const {enqueueSnackbar} = useSnackbar();

    useEffect(() => {
    }, [controlsData]);

    let popoverText;
    if(!controlsData)
        popoverText = "Игра недоступна";
    if(controlsData?.status === "WAITING_ACTIVE")
        popoverText = "Игра скоро начнется";
    if(controlsData?.status === "FINISHED")
        popoverText = "Игра завершена";


    useEffect(() => {
        const handleSpacePress = (event) => {
            if (event.code === 'Space') {
                event.stopPropagation();
                event.preventDefault();
                // Выполнение вашей функции
                AxiosPostWithoutState(apiUrl + "/democracy/user/pass", {"teamId" : team.id}, null)
                    .then(({loaded, error, data}) => {
                        if (error) {
                            enqueueSnackbar("Ошибка сервера при пропуске вопроса", {variant: "error"});
                            console.log("Ошибка сервера при пропуске вопроса: \n" + error);
                        } else {
                        }
                    }).catch(error => {
                        enqueueSnackbar("Ошибка сервера при пропуске вопроса", {variant: "error"});
                        console.log("Ошибка сервера при пропуске вопроса: \n" + error);
                });
            }
        };
        const handleKeyAPress = (event) => {
            if (event.code === 'KeyA') {
                event.stopPropagation();
                event.preventDefault();

                AxiosPostWithoutState(apiUrl + "/democracy/user/answer", {"teamId" : team.id}, null)
                    .then(({loaded, error, data}) => {
                        if (error) {
                            enqueueSnackbar("Ошибка сервера при ответе команды", {variant: "error"});
                            console.log("Ошибка сервера при ответе команды: \n" + error);
                        } else {
                        }
                    }).catch(error => {
                    enqueueSnackbar("Ошибка сервера при ответе команды", {variant: "error"});
                    console.log("Ошибка сервера при ответе команды: \n" + error);
                });
            }
        };

        // Добавление обработчика события
        document.addEventListener('keydown', handleSpacePress);
        document.addEventListener('keydown', handleKeyAPress);

        // Очистка обработчика после размонтирования компонента
        return () => {
            document.removeEventListener('keydown', handleSpacePress);
            document.removeEventListener('keydown', handleKeyAPress);
        };
    }, [team]);

    console.log("here");

    let classNameForBg = 'democracy-bg';
    if (controlsData?.timeIsActive){
        if(controlsData?.timeIsActive === true && parseInt(controlsData?.teamSequence) === parseInt(team?.orderId) && !controlsData?.currentQuestion?.questionKahoot)
            classNameForBg = 'democracy-bg blinking';
    }

    return (
        <>
            <Box height={"100%"} width={"100%"} className={classNameForBg}>
                <Box height={"25%"} width={"100%"} display={"inline-flex"}>
                    <DemocracyHeader controls={controlsData} team={team}/>
                </Box>
                <Box height={"50%"} width={"100%"}>
                    <DemocracyQuestion controls={controlsData} team={team}/>
                </Box>
                <Box height={"25%"} width={"100%"} display={"inline-flex"} bgcolor={"whitesmoke"} >
                    <DemocracySanctionWrapper teamId={team?.id} teamList={controlsData?.teamList}/>
                </Box>
                {popoverText ?
                    <MainBgPopover text={popoverText}/>
                    :
                    <></>
                }
            </Box>
            <QuestionPreStart controls={controlsData}/>
            <RoundDescriptionView  controls={controlsData}/>
            <RatingView controls={controlsData}/>
        </>
    );
}

function DemocracyHeader({controls, team}) {
    const theme = useTheme();

    const [time, setTime] = useState();
    UseWebSocket("/democracy/" + team?.name, "TIME", update);

    function update (newTime) {
        setTime(newTime);
    }


    useEffect(() => {
        if(team){
                setTime(team.timerTask.time);
        }
    }, [team]);

    console.log("DemocracyHeader")


    return(
        <>
            <Box className={"flex-col"} width={"20rem"} padding={"1rem"} border={"0.2rem solid " + theme.palette.primary.dark} bgcolor={"whitesmoke"} borderRadius={"0.5rem"}>
                <Box className={"flex-col-vcenter-hcenter m-b-1 "} width={"100%"}>
                    <Typography variant="h3" color={theme.palette.text.primary}>Управление</Typography>
                </Box>
                <Box sx={{flexGrow: 1}} className={"flex-col-space-between"}>
                        <Box className={"flex-row-space-between customBg p-button b-r-0_5"}>
                            <Box >
                                <Typography variant="h4" color={theme.palette.text.contrast}>Пропуск</Typography>
                            </Box>
                            <Box >
                                <Typography variant="h4" sx={{textDecoration: 'underline'}} color={theme.palette.text.contrast}>space</Typography>
                            </Box>
                        </Box>
                        <Box className={"flex-row-space-between customBg p-button b-r-0_5"}>
                            <Box >
                                <Typography variant="h4" color={theme.palette.text.contrast}>Ответ</Typography>
                            </Box>
                            <Box >
                                <Typography variant="h4" sx={{textDecoration: 'underline'}} color={theme.palette.text.contrast}>A</Typography>
                            </Box>
                        </Box>
                        <Box className={"flex-row-space-between customBg p-button b-r-0_5"}>
                            <Box >
                                <Typography variant="h4" color={theme.palette.text.contrast}>Санкции</Typography>
                            </Box>
                            <Box >
                                <Typography variant="h4" sx={{textDecoration: 'underline'}} color={theme.palette.text.contrast}> </Typography>
                            </Box>
                        </Box>
                        <Box className={"flex-row-space-between customBg p-button b-r-0_5"}>
                            <Box >
                                <Typography variant="h4" color={theme.palette.text.contrast}>Правила</Typography>
                            </Box>
                            <Box >
                                <Typography variant="h4" sx={{textDecoration: 'underline'}} color={theme.palette.text.contrast}> </Typography>
                            </Box>
                        </Box>
                </Box>
            </Box>
            <Box marginLeft={"1rem"} marginRight={"1rem"} sx={{flexGrow: 1, background: "linear-gradient(180deg, #041E7D 0%, #00155E 100%)"}} border={"0.2rem solid " + theme.palette.primary.dark} borderRadius={"0.5rem"} >
                <Grid container direction={"column"} margin={"unset"} spacing={0}>
                    <Grid item xs={12} sm={4} >
                        <Box margin={"1rem"} marginBottom={"unset"}>
                            <Grid container spacing={1}>
                                <Grid item xs={4}>
                                    <Box className={"flex-row-space-between bg-contrast p-button b-r-0_5"}>
                                        <Box >
                                            <Typography variant="h3" color={theme.palette.text.primary}>Раунд</Typography>
                                        </Box>
                                        <Box >
                                            <Typography variant="h3" color={theme.palette.text.primary}>{controls?.currentRound?.title}</Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box className={"flex-row-vcenter-hcenter"}>
                                        <Typography variant="h1" color={theme.palette.text.contrast}>{FormatTime(time)}</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box className={"flex-row-space-between bg-contrast p-button b-r-0_5"}>
                                        <Box >
                                            <Typography variant="h3" color={theme.palette.text.primary}>Баланс</Typography>
                                        </Box>
                                        <Box >
                                            <Typography variant="h3" color={theme.palette.text.primary}>{team?.balance}</Typography>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={8}  display={"flex"}>
                        <Box width={"100%"} sx={{flexGrow: 1}}>
                            <Grid container direction={"row"} spacing={1} height={"100%"} margin={"unset"}>
                                {team?.assignedTask.sort((a, b) => a.id - b.id).map((sanction, index) => (
                                    <Grid key={index} item xs={3}>
                                        <Box
                                            borderRadius={"0.5rem"}
                                            className={"bgCoverImage"}
                                            border={"0.2rem solid " + sanction.buyerTeam?.color}
                                            sx={{height: "100%", "backgroundImage": sanction.mediaFile ? "url(" + staticPath + sanction.mediaFile.url + ")": TransparentImage}}
                                            display="flex"
                                            flexDirection="column">
                                            <Box>
                                                <Typography variant="h4" textAlign={"center"} color={theme.palette.text.contrast} component="div">
                                                    {sanction.title}
                                                </Typography>
                                                <Typography variant="h4" textAlign={"center"} color={theme.palette.text.contrast} component="div">
                                                    Осталось: {sanction.duration}
                                                </Typography>
                                            </Box>
                                            <Box marginTop="auto">
                                                <Typography variant="h4" padding={"0.5rem"} color={theme.palette.text.contrast}>
                                                    {sanction.description}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>

                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box className={"flex-col"} width={"20rem"} padding={"1rem"} border={"0.2rem solid " + theme.palette.primary.dark} bgcolor={"whitesmoke"}  borderRadius={"0.5rem"}>
                <Box className={"flex-col-vcenter-hcenter m-b-1"} width={"100%"}>
                    <Typography variant="h3" color={theme.palette.text.primary}>Рейтинг</Typography>
                </Box>
                <Box sx={{flexGrow: 1}} className={"flex-col-space-between"}>

                    {controls?.teamList?.sort((a, b) => b.rating - a.rating).map((team, index) => (
                        <Box key={index} className={"flex-row-space-between customBg p-button b-r-0_5"}>
                            <Box >
                                <Typography variant="h4" color={theme.palette.text.contrast}>{team.name}</Typography>
                            </Box>
                            <Box >
                                <Typography variant="h4" color={theme.palette.text.contrast}>{team.rating}</Typography>
                            </Box>
                        </Box>
                    ))}
                </Box>
            </Box>
        </>
    )
}

function DemocracyQuestion({controls, team}) {

    const {enqueueSnackbar} = useSnackbar();
    const [bigQuestionViewActive, setBigQuestionViewActive] = useState(false)
    const [bigAnswerViewActive, setBigAnswerViewActive] = useState(false)
    const theme = useTheme();
    const [mediaShowed, setMediaShowed] = useState(false);


    console.log("DemocracyQuestion");


    const [time, setTime] = useState();
    UseWebSocket("/democracy/" + team?.name, "TIME", update, "end_time");

    function update (newTime) {
        setTime(newTime);
    }

    useEffect(() => {
        setMediaShowed(false);
    }, [controls?.currentQuestion?.id]);

    useEffect(() => {
        if(team){
            setTime(team.timerTask.time);
        }
    }, [team]);



    const [selectedAnswerForQuestion, setSelectedAnswerForQuestion] = useState({
        id: controls?.currentQuestion?.id,
        answer: team?.answer,
    });

    useEffect(() => {
        setSelectedAnswerForQuestion({
            id: controls?.currentQuestion?.id,
            answer: team?.answer,
        });
    }, [controls?.currentQuestion, team])

    let timeoutShowBigQuestion;
    function showBigQuestionView () {
      setBigQuestionViewActive(true);
      if(timeoutShowBigQuestion) clearTimeout(timeoutShowBigAnswer);

        timeoutShowBigQuestion = setTimeout(() => {
          setBigQuestionViewActive(false);
          setMediaShowed(true);
      },5000)
    }

    let timeoutShowBigAnswer;
    function showBigAnswerView () {
        setBigAnswerViewActive(true);
        if(timeoutShowBigAnswer) clearTimeout(timeoutShowBigAnswer);
            timeoutShowBigAnswer = setTimeout(() => {
                setBigAnswerViewActive(false);
                setMediaShowed(true);
            },5000)
    }

    useEffect(() => {
        if(controls && controls?.questionIsShow === true) {
            const mediaList = controls.currentQuestion?.mediaList.filter(m => m.fileExtensionType === "IMAGE");
            if (mediaList.length > 0){
                showBigQuestionView();
            }
        }

        if(controls && controls?.answerIsShow === true) {
            const mediaList = controls.currentQuestion?.mediaList.filter(m => m.fileExtensionType === "IMAGE");
            if (mediaList.length > 0){
                showBigAnswerView();
            }
        }
    }, [controls]);


    function selectKahootAnswer(e, answer) {
        console.log("selectKahootAnswer");
        e.preventDefault();
        e.stopPropagation();

        if(!selectedAnswerForQuestion.answer)
            setSelectedAnswerForQuestion({
                id: selectedAnswerForQuestion.id,
                answer: answer
            })

        AxiosPostWithoutState(apiUrl + "/democracy/user/answer/kahoot", {"teamId" : team.id, "answer": answer}, null)
            .then(({loaded, error, data}) => {
                if (error) {
                    enqueueSnackbar("Произошла ошибка при выборе варианта ответа", {variant: "error"});
                    console.log("Произошла ошибка при выборе варианта ответа: \n" + error);
                } else {
                }
            }).catch(error => {
            enqueueSnackbar("Произошла ошибка при выборе варианта ответа", {variant: "error"});
            console.log("Произошла ошибка при выборе варианта ответа: \n" + error);
        });

    }

    let mediaList = [];
    if(controls && controls.currentQuestion && controls.currentQuestion.mediaList){
        mediaList = controls.currentQuestion.mediaList.filter(m => m.fileExtensionType === "IMAGE")
    }
    let questionData = <></>;

    let questionClassName = 'customBg flex-col-vcenter-hcenter';

    if(team?.assignedTask.filter(sanction => sanction.title === 'Перевертыш')[0])
        questionClassName = 'question customBg flex-col-vcenter-hcenter rotate';
    if(team?.assignedTask.filter(sanction => sanction.title === 'Сумасшедший вопрос')[0])
        questionClassName = 'question customBg flex-col-vcenter-hcenter crazy-question';
    if(team?.assignedTask.filter(sanction => sanction.title === 'Кружится голова')[0])
        questionClassName = 'question customBg flex-col-vcenter-hcenter full-rotate';


    console.log("test kahoot");
    if((controls && controls?.questionIsShow === true)){
        if(mediaList.length === 0){
            questionData = <Box className={questionClassName} borderRadius={"1rem"} padding={"2rem"} width={"80%"} minHeight={"30%"}>
                    <Typography variant="bodyLarge" textAlign={"center"} justifyContent={"center"} color={theme.palette.text.contrast} component="div">
                        {FormatQuestionWithSanction(controls.currentQuestion.question, team?.assignedTask)}
                    </Typography>
                    {controls.currentQuestion?.questionKahoot ?
                        <Grid container spacing={2} className={"flex-row-vstart-hcenter"} marginTop={"1rem"}>
                            <Grid item xs={4}>
                                <Box borderRadius={"0.5rem"} padding={"0.5rem 0.5rem"} bgcolor={FormatOptionColor(controls, controls.currentQuestion.questionKahoot.option1, selectedAnswerForQuestion)} onClick={e => selectKahootAnswer(e, controls.currentQuestion.questionKahoot.option1)}>
                                    <Typography variant="bodyPreLarge" textAlign={"center"} justifyContent={"center"} color={theme.palette.text.contrast} component="div">
                                        {controls.currentQuestion.questionKahoot.option1}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                <Box borderRadius={"0.5rem"} padding={"0.5rem 0.5rem"} bgcolor={FormatOptionColor(controls, controls.currentQuestion.questionKahoot.option2, selectedAnswerForQuestion)} onClick={e => selectKahootAnswer(e, controls.currentQuestion.questionKahoot.option2)}>
                                    <Typography variant="bodyPreLarge" textAlign={"center"} justifyContent={"center"} color={theme.palette.text.contrast} component="div">
                                        {controls.currentQuestion.questionKahoot.option2}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                <Box borderRadius={"0.5rem"} padding={"0.5rem 0.5rem"} bgcolor={FormatOptionColor(controls, controls.currentQuestion.questionKahoot.option3, selectedAnswerForQuestion)} onClick={e => selectKahootAnswer(e, controls.currentQuestion.questionKahoot.option3)}>
                                    <Typography variant="bodyPreLarge" textAlign={"center"} justifyContent={"center"} color={theme.palette.text.contrast} component="div">
                                        {controls.currentQuestion.questionKahoot.option3}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        :
                        <></>
                    }
                </Box>;
        }
        else if (mediaList.length > 0){
            questionData = <Box className={"preview"} width={"fit-content"} height={"100%"} maxHeight={"90%"} minWidth={"30%"} position={'relative'}  >
                {mediaList.length === 1 ?
                    <img src={staticPath + "/" + controls.currentQuestion?.mediaList[0]?.url}
                         style={{maxHeight: '100%'}} onClick={e => setBigQuestionViewActive(true)}/>
                    : (
                        mediaList.length > 1 && controls.mediaIdToShow ?
                        <img src={staticPath + "/" + controls.currentQuestion?.mediaList.filter(m => m.id === controls.mediaIdToShow)[0]?.url}
                             style={{maxHeight: '100%'}} onClick={e => setBigQuestionViewActive(true)}/>
                        :
                        <></>
                    )
                }
                <Box position={"absolute"} bottom={"1rem"} left={"1rem"} right={"1rem"} className={questionClassName} borderRadius={"1rem"} padding={"2rem"}>
                    <Typography variant="bodyPreLarge" textAlign={"center"} justifyContent={"center"} color={theme.palette.text.contrast} component="div">
                        {FormatQuestionWithSanction(controls.currentQuestion.question, team?.assignedTask)}
                    </Typography>
                    {controls.currentQuestion?.questionKahoot ?
                        <Grid container spacing={2} className={"flex-row-vstart-hcenter"} marginTop={"1rem"}>
                            <Grid item xs={4}>
                                <Box borderRadius={"0.5rem"} padding={"0.5rem 0.5rem"} bgcolor={FormatOptionColor(controls, controls.currentQuestion.questionKahoot.option1, selectedAnswerForQuestion)} onClick={e => selectKahootAnswer(e, controls.currentQuestion.questionKahoot.option1)}>
                                    <Typography variant="bodyPreLarge" textAlign={"center"} justifyContent={"center"} color={theme.palette.text.contrast} component="div">
                                    {controls.currentQuestion.questionKahoot.option1}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                <Box borderRadius={"0.5rem"} padding={"0.5rem 0.5rem"} bgcolor={FormatOptionColor(controls, controls.currentQuestion.questionKahoot.option2, selectedAnswerForQuestion)} onClick={e => selectKahootAnswer(e, controls.currentQuestion.questionKahoot.option2)}>
                                    <Typography variant="bodyPreLarge" textAlign={"center"} justifyContent={"center"} color={theme.palette.text.contrast} component="div">
                                        {controls.currentQuestion.questionKahoot.option2}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={4}>
                                <Box borderRadius={"0.5rem"} padding={"0.5rem 0.5rem"} bgcolor={FormatOptionColor(controls, controls.currentQuestion.questionKahoot.option3, selectedAnswerForQuestion)} onClick={e => selectKahootAnswer(e, controls.currentQuestion.questionKahoot.option3)}>
                                    <Typography variant="bodyPreLarge" textAlign={"center"} justifyContent={"center"} color={theme.palette.text.contrast} component="div">
                                        {controls.currentQuestion.questionKahoot.option3}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        :
                        <></>
                    }
                </Box>
            </Box>
        }
    }

    if(controls && controls?.answerIsShow === true && !controls.currentQuestion?.questionKahoot){
        questionData =
            <Box className={"preview"} width={"fit-content"} height={"100%"} maxHeight={"90%"} minWidth={"30%"} position={'relative'}  >
                {mediaList.length === 1 ?
                    <img src={staticPath + "/" + controls.currentQuestion?.mediaList[0]?.url}
                         style={{maxHeight: '100%'}} onClick={e => setBigAnswerViewActive(true)}/>
                    : (
                        mediaList.length > 1 ?
                            <img src={staticPath + "/" + controls.currentQuestion?.mediaList.slice(-1)[0]?.url}
                                 style={{maxHeight: '100%'}} onClick={e => setBigAnswerViewActive(true)}/>
                            :
                            <></>
                    )
                }
                <Box  position={"absolute"} bottom={"1rem"} left={"1rem"} right={"1rem"} className={"customBgSuccess flex-col-vcenter-hcenter"} borderRadius={"1rem"} padding={"2rem"}>
                    <Typography variant="bodyExtraLarge" textAlign={"center"} justifyContent={"center"} color={theme.palette.text.contrast} component="div">
                        {controls.currentQuestion.answer}
                    </Typography>
                </Box>;
            </Box>
    }

    if(controls && controls?.answerIsShow === true && controls.currentQuestion?.questionKahoot){
        questionData = <Box className={questionClassName} borderRadius={"1rem"} padding={"2rem"} width={"80%"} minHeight={"30%"}>
            <Typography variant="bodyPreLarge" textAlign={"center"} justifyContent={"center"} color={theme.palette.text.contrast} component="div">
                {FormatQuestionWithSanction(controls.currentQuestion.question, team?.assignedTask)}
            </Typography>
            {controls.currentQuestion?.questionKahoot ?
                <Grid container spacing={2} className={"flex-row-vstart-hcenter"} marginTop={"1rem"}>
                    <Grid item xs={4}>
                        <Box borderRadius={"0.5rem"} padding={"0.5rem 0.5rem"} bgcolor={FormatOptionColor(controls, controls.currentQuestion.questionKahoot.option1, selectedAnswerForQuestion)} onClick={e => selectKahootAnswer(e, controls.currentQuestion.questionKahoot.option1)}>
                            <Typography variant="bodyPreLarge" textAlign={"center"} justifyContent={"center"} color={theme.palette.text.contrast} component="div">
                                {controls.currentQuestion.questionKahoot.option1}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <Box borderRadius={"0.5rem"} padding={"0.5rem 0.5rem"} bgcolor={FormatOptionColor(controls, controls.currentQuestion.questionKahoot.option2, selectedAnswerForQuestion)} onClick={e => selectKahootAnswer(e, controls.currentQuestion.questionKahoot.option2)}>
                            <Typography variant="bodyPreLarge" textAlign={"center"} justifyContent={"center"} color={theme.palette.text.contrast} component="div">
                                {controls.currentQuestion.questionKahoot.option2}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <Box borderRadius={"0.5rem"} padding={"0.5rem 0.5rem"} bgcolor={FormatOptionColor(controls, controls.currentQuestion.questionKahoot.option3, selectedAnswerForQuestion)} onClick={e => selectKahootAnswer(e, controls.currentQuestion.questionKahoot.option3)}>
                            <Typography variant="bodyPreLarge" textAlign={"center"} justifyContent={"center"} color={theme.palette.text.contrast} component="div">
                                {controls.currentQuestion.questionKahoot.option3}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                :
                <></>
            }
        </Box>;
    }

    return(<>
        <Box width={"100%"} height={"100%"} className={"flex-row-space-between"}>
            <Box width={"25rem"} height={"100%"} className={"bgFitImage m-t-1"} sx={{"backgroundImage": "url(" + PoliticLeft + ")"}}>

            </Box>
            <Box sx={{flexGrow: 1}} maxWidth={"70%"} maxHeight={"100%"} height={"100%"}  className={"flex-row-vcenter-hcenter"} position={'relative'}>
                {questionData}
            </Box>
            {ConvertTimeStringToSeconds(time) < 1  ?
                <Box position={"absolute"} bottom={"1rem"} left={"1rem"} right={"1rem"} bgcolor={theme.palette.error.dark}
                     borderRadius={"1rem"} padding={"2rem"}>
                    <Typography variant="bodyLarge" textAlign={"center"} justifyContent={"center"}
                                color={theme.palette.text.contrast} component="div">
                        Время закончилось
                    </Typography>
                </Box>
                :
                <></>
            }
            <Box width={"25rem"} height={"100%"} className={"bgFitImage m-t-0_5"} sx={{"backgroundImage": "url(" + PoliticRight + ")"}}>

            </Box>
        </Box>
        {bigQuestionViewActive === true && controls?.questionIsShow === true ?
            <Box className={"full-screen flex-col-vcenter-hcenter"} zIndex={100}  onClick={e => setBigQuestionViewActive(false)}>
                <Box className={"preview preview-image"} position={"relative"} width={"fit-content"} height={"90%"}  >
                    {mediaList.length === 1 ?
                        <img src={staticPath + "/" + controls.currentQuestion?.mediaList[0]?.url}
                             style={{height: '100%'}}/>
                        : (
                            mediaList.length > 1 && controls.mediaIdToShow ?
                                <img src={staticPath + "/" + controls.currentQuestion?.mediaList.filter(m => m.id === controls.mediaIdToShow)[0]?.url}
                                     style={{height: '100%'}}/>
                                :
                                <></>
                        )
                    }
                    <Box position={"absolute"} bottom={"1rem"} left={"1rem"} right={"1rem"} className={questionClassName} borderRadius={"1rem"} padding={"2rem"}>
                        <Typography variant="bodyLarge" textAlign={"center"} justifyContent={"center"} color={theme.palette.text.contrast} component="div">
                            {FormatQuestionWithSanction(controls.currentQuestion.question, team?.assignedTask)}
                        </Typography>
                        {controls.currentQuestion?.questionKahoot ?
                            <Grid container spacing={2} className={"flex-row-vstart-hcenter"} marginTop={"1rem"}>
                                <Grid item xs={4}>
                                    <Box borderRadius={"0.5rem"} padding={"0.5rem 0.5rem"} bgcolor={FormatOptionColor(controls, controls.currentQuestion.questionKahoot.option1, selectedAnswerForQuestion)} onClick={e => selectKahootAnswer(e, controls.currentQuestion.questionKahoot.option1)}>
                                        <Typography variant="bodyPreLarge" textAlign={"center"} justifyContent={"center"} color={theme.palette.text.contrast} component="div">
                                            {controls.currentQuestion.questionKahoot.option1}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box borderRadius={"0.5rem"} padding={"0.5rem 0.5rem"} bgcolor={FormatOptionColor(controls, controls.currentQuestion.questionKahoot.option2, selectedAnswerForQuestion)} onClick={e => selectKahootAnswer(e, controls.currentQuestion.questionKahoot.option2)}>
                                        <Typography variant="bodyPreLarge" textAlign={"center"} justifyContent={"center"} color={theme.palette.text.contrast} component="div">
                                            {controls.currentQuestion.questionKahoot.option2}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={4}>
                                    <Box borderRadius={"0.5rem"} padding={"0.5rem 0.5rem"} bgcolor={FormatOptionColor(controls, controls.currentQuestion.questionKahoot.option3, selectedAnswerForQuestion)} onClick={e => selectKahootAnswer(e, controls.currentQuestion.questionKahoot.option3)}>
                                        <Typography variant="bodyPreLarge" textAlign={"center"} justifyContent={"center"} color={theme.palette.text.contrast} component="div">
                                            {controls.currentQuestion.questionKahoot.option3}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                            :
                            <></>
                        }
                    </Box>

                </Box>
            </Box>
            :
            <></>
        }

        {bigAnswerViewActive === true && controls?.answerIsShow === true ?
            <Box className={"full-screen flex-col-vcenter-hcenter"} zIndex={100}  onClick={e => setBigAnswerViewActive(false)}>
                <Box className={"preview preview-image"} position={"relative"} width={"fit-content"} height={"90%"}  >
                    {mediaList.length === 1 ?
                        <img src={staticPath + "/" + controls.currentQuestion?.mediaList[0]?.url}
                             style={{height: '100%'}}/>
                        : (
                            mediaList.length > 1 && controls.mediaIdToShow ?
                                <img src={staticPath + "/" + controls.currentQuestion?.mediaList.slice(-1)[0]?.url}
                                     style={{height: '100%'}}/>
                                :
                                <></>
                        )
                    }
                    <Box position={"absolute"} bottom={"1rem"} left={"1rem"} right={"1rem"} className={"customBgSuccess flex-col-vcenter-hcenter"} borderRadius={"1rem"} padding={"2rem"}>
                        <Typography variant="bodyLarge" textAlign={"center"} justifyContent={"center"} color={theme.palette.text.contrast} component="div">
                            {controls.currentQuestion.answer}
                        </Typography>
                    </Box>

                </Box>
            </Box>
            :
            <></>
        }
    </>);
}


function MainBgPopover({text}) {
    const theme = useTheme();
    return (
        <Box className="full-screen flex-col-vcenter-hcenter preview preview-image" border={"unset"} borderRadius={"unset"} bgcolor={"#000000c9"} sx={{"backgroundImage": "url(" + MainPopover + ")"}}>
            <Box  padding={"2rem"} borderRadius={"1rem"} bgcolor={"#f5f5f5e6"}>
                <Typography variant="h1" textAlign={"center"} justifyContent={"center"} color={theme.palette.text.primary} component="div">
                    {text}
                </Typography>
            </Box>
        </Box>
    )
}

function QuestionPreStart({controls}) {
    console.log("QuestionPreStart");
    const theme = useTheme();
    const [countdown, setCountdown] = useState("NONE"); // Инициализация состояния таймера
    UseWebSocket("/democracy", "PRE_START_TIME", update);

    function update (newTime) {
        setCountdown(newTime);
    }

    useEffect(() => {
        if(controls && (controls.timeIsActive === true || controls.questionIsShow === true || controls.answerIsShow === true))
            setCountdown("NONE");
    },[controls] );

    if(countdown !== "NONE")
        return (
            <div className="full-screen flex-col-vcenter-hcenter">
                <div className={`countdown-timer ${countdown === 0 ? 'finished' : ''}`}>
                    <Typography variant="extraH1" textAlign={"center"} justifyContent={"center"} color={theme.palette.text.contrast} component="div">
                        {countdown}
                    </Typography>
                </div>
            </div>
        )
}


function RoundDescriptionView({controls}) {
    const theme = useTheme();

    if(controls?.roundDescriptionIsShow === true)
        return (
            <Box className={"full-screen flex-col-vcenter-hcenter"} zIndex={100}  >
                <Box className={"preview preview-image"} position={"relative"} width={"fit-content"} height={"90%"}  >
                    <img src={controls.currentRound?.mediaFile?.url ? staticPath + "/" + controls.currentRound?.mediaFile?.url : TransparentImage} height={"100%"}/>
                    <Box position={"absolute"} bottom={"1rem"} left={"1rem"} right={"1rem"} className={"customBg"} borderRadius={"1rem"} padding={"2rem"}>
                        <Typography variant="bodyLarge" textAlign={"center"} justifyContent={"center"} color={theme.palette.text.contrast} component="div">
                            {controls.currentRound.description}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        )
}


function RatingView({controls}) {
    console.log("RatingView");
    const theme = useTheme();


    if(controls?.ratingIsShow === true)
        return (
            <Box className={"full-screen flex-col-vcenter-hcenter"} zIndex={100}  >
                <Box width={"60rem"} className={"white-container"} >
                    {controls?.teamList?.sort((a, b) => b.rating - a.rating).map((team, index) => (
                        <Box key={index} className={"flex-row-space-between customBg p-button b-r-0_5"} marginBottom={"0.5rem"}>
                            <Box >
                                <Typography variant="h2" color={theme.palette.text.contrast}>{team.name}</Typography>
                            </Box>
                            <Box >
                                <Typography variant="h2" color={theme.palette.text.contrast}>{team.rating}</Typography>
                            </Box>
                        </Box>
                    ))}
                </Box>
            </Box>
        )
}


function SanctionBuy({selectedSanction, teamList, teamId}) {
    const theme = useTheme();
    const {enqueueSnackbar} = useSnackbar()
    const [duration, setDuration] = useState(1);
    const [targetTeamId, setTargetTeamId] = useState();
    const [totalCost, setTotalCost] = useState(duration * selectedSanction.cost);


    const handleDuration = (duration) => {
        setTotalCost(duration * selectedSanction.cost);
        setDuration(duration);
    }

    const handleClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
        // Далее можете вызвать ваше API для сохранения изменений на бэкенде
        AxiosPostWithoutState(apiUrl + "/democracy/user/sanction/buy", {"sanctionId": selectedSanction.id, "duration" : duration, "buyerTeamId": teamId, "targetTeamId": targetTeamId}, null).then(({loaded, error, data}) => {
            if(error){
                enqueueSnackbar(error, {variant: "error"});
                console.log("Произошла ошибка при покупке задания: \n" + error);
            }
            else {
                enqueueSnackbar("Сохранено", {variant: "success"});
            }
        }).catch(error => {
            enqueueSnackbar("Произошла ошибка при покупке задания", {variant: "error"});
            console.log("Произошла ошибка при покупке задания: \n" + error);
        });
    };

    let durationValueSet = [1,2,3,4,5].map((num) => ({
        key: num,
        value: num,
    }));

    let teamValueSet;
    if(teamList) {
        teamValueSet = teamList.map((team) => ({
            key: team.id,
            value: team.name,
        }));
    }

    return (
        <Box
            className={"bgCoverImage b-r-0_5 m-l-1 p-0_5 flex-grow"}
            sx={{flexGrow: 1, marginBottom: "0.5rem", "backgroundImage": selectedSanction.mediaFile ? "url(" + staticPath + selectedSanction.mediaFile.url + ")" : TransparentImage}}
            border={"1px solid " + theme.palette.grey["300"]} padding={"0.5rem"} display="flex" flexDirection="column">
            <Box>
                <Typography variant="h4" textAlign={"center"} color={theme.palette.text.contrast} component="div">
                    {selectedSanction.title}
                </Typography>
                <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography variant="h4" textAlign={"center"} color={theme.palette.text.contrast} component="div">
                        Цена за круг:
                    </Typography>
                    <Typography variant="h4" textAlign={"center"} color={theme.palette.text.contrast} component="div">
                        {selectedSanction.cost}
                    </Typography>
                </Box>
                <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography variant="h4" textAlign={"center"} color={theme.palette.text.contrast} component="div">
                        Всего:
                    </Typography>
                    <Typography variant="h4" textAlign={"center"} color={theme.palette.text.contrast} component="div">
                        {totalCost}
                    </Typography>
                </Box>
                <Box>
                    <SelectValueFullWidth
                        valuesSet={durationValueSet}
                        selectedValue={duration}
                        onChangeFunction={handleDuration}
                        disabled={false}/>
                </Box>

                <Box>
                    <SelectValueFullWidth
                        valuesSet={teamValueSet}
                        selectedValue={targetTeamId}
                        onChangeFunction={setTargetTeamId}
                        disabled={false}/>
                </Box>
            </Box>

            <Box marginTop="auto">
                <Button variant="contained" sx={{width: '100%', padding: 'unset', backgroundColor: theme.palette.primary.dark}}
                        color="primary" onClick={e => handleClick(e)}>
                    <Typography variant="button" color={theme.palette.text.contrast}>
                        Купить
                    </Typography>
                </Button>
            </Box>
        </Box>
    );
}


function FormatTime(timeString) {
    if(timeString) {
        const timeParts = timeString.split(':');
        const minutes = parseInt(timeParts[1], 10).toString().padStart(2, '0');
        const seconds = parseInt(timeParts[2], 10).toString().padStart(2, '0');
        const formattedTime = `${minutes}:${seconds}`;

        return (formattedTime);
    }
    else {
        return <></>
    }
}

function ConvertTimeStringToSeconds(timeString){
    if(!timeString)
        return 100;
    const timeParts = timeString.split(':');
    const minutes = parseInt(timeParts[1], 10).toString().padStart(2, '0');
    const seconds = parseInt(timeParts[2], 10).toString().padStart(2, '0');

    console.log("seconds = " + seconds);
    return minutes * 60 + seconds;
}

// useEffect(() => {
//     const handleSpacePress = (event) => {
//         if (event.code === 'Space') {
//             // Выполнение вашей функции
//             AxiosPostWithoutState(apiUrl + "/democracy/user/pass", {"teamId" : team.id}, null)
//                 .then(({loaded, error, data}) => {
//                     if (error) {
//                         enqueueSnackbar("Ошибка сервера при пропуске вопроса", {variant: "error"});
//                         console.log("Ошибка сервера при пропуске вопроса: \n" + error);
//                     } else {
//                     }
//                 }).catch(error => {
//                     enqueueSnackbar("Ошибка сервера при пропуске вопроса", {variant: "error"});
//                     console.log("Ошибка сервера при пропуске вопроса: \n" + error);
//             });
//         }
//     };
//     const handleKeyAPress = (event) => {
//         console.log(event.code);
//         if (event.code === 'KeyA') {
//
//             AxiosPostWithoutState(apiUrl + "/democracy/user/answer", {"teamId" : team.id}, null)
//                 .then(({loaded, error, data}) => {
//                     if (error) {
//                         enqueueSnackbar("Ошибка сервера при ответе команды", {variant: "error"});
//                         console.log("Ошибка сервера при ответе команды: \n" + error);
//                     } else {
//                     }
//                 }).catch(error => {
//                 enqueueSnackbar("Ошибка сервера при ответе команды", {variant: "error"});
//                 console.log("Ошибка сервера при ответе команды: \n" + error);
//             });
//         }
//     };
//
//     // Добавление обработчика события
//     document.addEventListener('keydown', handleSpacePress);
//     document.addEventListener('keydown', handleKeyAPress);
//
//     // Очистка обработчика после размонтирования компонента
//     return () => {
//         document.removeEventListener('keydown', handleSpacePress);
//         document.removeEventListener('keydown', handleKeyAPress);
//     };
// }, [team]);


function FormatOptionColor(controls,option, selectedAnswerForQuestion) {
    const theme = useTheme();

    if(selectedAnswerForQuestion && selectedAnswerForQuestion.answer && selectedAnswerForQuestion.answer?.length > 1){

        if(controls.answerIsShow === true) {
            if (option === controls.currentQuestion.answer)
                return theme.palette.success.dark;

            if (option === selectedAnswerForQuestion.answer && option !== controls.currentQuestion.answer)
                return theme.palette.error.dark;

            return theme.palette.warning.dark;
        }
        else{
            console.log(option + " : " + selectedAnswerForQuestion.answer);
            if (option === selectedAnswerForQuestion.answer) {
                console.log("correct");
                return theme.palette.secondary.main;
            }
        }

    }

    return theme.palette.warning.dark;
}

function FormatQuestionWithSanction(question, sanctionList) {

    if(sanctionList.filter(sanction => sanction.title.trim() === 'Алфавит'.trim())[0])
        return removeVowels(question);
    if(sanctionList.filter(sanction => sanction.title.trim() === 'Алфавит 2.0'.trim())[0])
        return removeConsonants(question);
    if(sanctionList.filter(sanction => sanction.title.trim() === 'Большой АПОЖ'.trim())[0])
        return reverseString(question);
    if(sanctionList.filter(sanction => sanction.title.trim() === 'Реверс'.trim())[0])
        return swapAdjacentWords(question);
    else
        return question;
}

function removeConsonants(text) {
    const consonants = /[бвгджзйклмнпрстфхцчшщbcdfghjklmnpqrstvwxyz]/ig;
    return text.replace(consonants, '');
}

function removeVowels(text) {
    const vowels = /[аеёиоуыэюяaeiou]/ig;
    return text.replace(vowels, '');
}

function reverseString(text) {
    return text.split('').reverse().join('');
}

function swapAdjacentWords(text) {
    const words = text.split(/\s+/);
    for (let i = 0; i < words.length - 1; i += 2) {
        const temp = words[i];
        words[i] = words[i + 1];
        words[i + 1] = temp;
    }
    return words.join(' ');
}
