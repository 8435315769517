import {useSnackbar} from "notistack";
import {AxiosGet} from "../axios/AxiosGet";
import {apiUrl, staticPath} from "../schema/Environment";
import {useTheme} from "@mui/system";
import CustomContentForLoadAndError from "../components/CustomContentForLoadAndError";
import {Box, Grid, Typography, useMediaQuery} from "@mui/material";
import React, {useState} from "react";
import TransparentImage from "../../images/backgrounds/transparent.png";
import {ColumnBox, InlineBox} from "../components/Containers";
import ContentLoader from "react-content-loader";

export default function DemocracySanctions({handleSanctionSelect}) {
    const {enqueueSnackbar} = useSnackbar()
    const {
        loaded: loadedTypes,
        error: errorTypes,
        data: loadedSanctionsTypes
    } = AxiosGet(apiUrl + "/admin/democracy/sanction/findAllTypes", null, enqueueSnackbar);
    const {
        loaded: loadedList,
        error: errorList,
        data: loadedSanctionsList
    } = AxiosGet(apiUrl + "/admin/democracy/sanction/findAll",  null, enqueueSnackbar);

    const theme = useTheme();

    if (!loadedTypes && errorTypes === null) {
        // один или оба запроса все еще загружаются
        return CustomContentForLoadAndError(loadedTypes, errorTypes, "Смена", <DemocracySanctionsPreloader loadIsActive={true}/>);
    }
    if (!loadedList && errorList === null) {
        // один или оба запроса все еще загружаются
        return CustomContentForLoadAndError(loadedList, errorList, "Смена", <DemocracySanctionsPreloader loadIsActive={true}/>);
    }

    if(loadedSanctionsTypes !== null && loadedSanctionsList !== null) {
        const typeSize = loadedSanctionsTypes.length;
        const gridSize = 12 / typeSize;
        return (
            <Box className={"width-p-100 height-p-100 flex-row"}>
                <Box className={"width-p-100 height-p-100 flex-column"}>
                    <Grid container spacing={1} className={"width-p-100 m-b-1 customBg"}
                          sx={{borderRadius: "0.5rem 0 0 0.5rem"}}>
                        {loadedSanctionsTypes.sort((a, b) => a.orderId - b.orderId).map((sanctionType, index) => (
                            <Grid key={sanctionType.name} item xs={gridSize}>
                                <Box className={"m-b-1"}>
                                    <Typography variant="h3" color={theme.palette.text.contrast}
                                                textAlign={"center"}>{sanctionType.name}</Typography>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>

                    <Box className={"width-p-100 height-p-100"} sx={{overflowX: 'auto', height: '83%'}}>
                        <Grid container spacing={1} className={"width-p-100 flex-grow overflow-x"}>
                            {loadedSanctionsTypes.sort((a, b) => a.orderId - b.orderId).map((sanctionType, index) => (
                                <Grid key={sanctionType.name} item xs={gridSize}>
                                    <DemocracySanctionsList key={index} sanctionType={sanctionType}
                                                            loadedSanctionsList={loadedSanctionsList}
                                                            handleSanctionSelect={handleSanctionSelect}/>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Box>

            </Box>
        )
    }else{
        return (<DemocracySanctionsPreloader loadIsActive={false}/>)
    }
}

function DemocracySanctionsPreloader({loadIsActive}){
    const theme = useTheme();
    return (
        <Box className={"width-p-100 height-p-100 flex-row"}>
            <Box className={"width-p-100 height-p-100 flex-column"}>
                <Box className={"m-b-1 customBg"} marginTop={"-8px"}  marginLeft={"-8px"} boxSizing={"border-box"} padding={"9px"} sx={{borderRadius: "0.5rem 0 0 0.5rem"}}>
                    <Typography variant="h3" color={theme.palette.text.contrast} textAlign={"center"}>Санкции</Typography>
                </Box>
                {loadIsActive ?
                    <ContentLoader
                    speed={2}
                    // width={400}
                    // height={160}
                    viewBox="0 0 400 160"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb">
                    <rect x="0" y="8" rx="3" ry="3" width="88" height="6" />
                    <rect x="0" y="26" rx="3" ry="3" width="52" height="6" />
                    <rect x="0" y="100" rx="3" ry="3" width="410" height="6" />
                    <rect x="0" y="72" rx="3" ry="3" width="380" height="6" />
                    <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
                    </ContentLoader>
                    :
                    <></>
                }

            </Box>
        </Box>
    )
}


function DemocracySanctionsList({sanctionType, loadedSanctionsList, handleSanctionSelect}) {
    const [sanctionsList] = useState(loadedSanctionsList.filter(e => e.sanctionType === sanctionType.value));
    return (<>
        <Grid container direction={"column"} spacing={1}>
            {sanctionsList.sort((a, b) => a.id - b.id).map((sanction, index) => (
                <Grid key={index} item xs={12}>
                    <Sanction sanction={sanction} handleSanctionSelect={handleSanctionSelect}/>
                </Grid>
            ))}
        </Grid>

    </>);
}

function DemocracyTargetSanctionsList({loadedSanctionsList}) {
    const [sanctionsList] = useState(loadedSanctionsList);
    return (<>
        <Grid container direction={"column"} spacing={1}>
            {sanctionsList.sort((a, b) => a.id - b.id).map((sanction, index) => (
                <Grid key={index} item xs={12}>
                    <Sanction sanction={sanction} handleSanctionSelect={() => {}}/>
                </Grid>
            ))}
        </Grid>

    </>);
}

function Sanction({sanction, handleSanctionSelect}) {
    const theme = useTheme();
    return (
        <ColumnBox
             className={"bgCoverImage b-r-0_5 p-0_5 height-a-10"}
             sx={{"backgroundImage": sanction.mediaFile ? "url(" + staticPath + sanction.mediaFile.url + ")" : TransparentImage}}
             onClick={e => handleSanctionSelect(sanction)}>
            <Box>
                <Typography variant="h4" textAlign={"center"} color={theme.palette.text.contrast}
                            component="div">
                    {sanction.title}
                </Typography>
                <Typography variant="h4" textAlign={"center"} color={theme.palette.text.contrast}
                            component="div">
                    Цена: {sanction.cost}
                </Typography>
            </Box>
            <Box marginTop="auto">
                <Typography variant="bodyMedium" color={theme.palette.text.contrast}>
                    {sanction.description}
                </Typography>
            </Box>
        </ColumnBox>
    )
}
