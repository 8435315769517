import { useState } from 'react';

function UseToken() {
    const getToken = () => {
        const tokenString = sessionStorage.getItem('token');
        if(tokenString === 'undefined' || tokenString === 'null')
            return undefined;
        else
            return tokenString
    };

    const [token, setToken] = useState(getToken());

    const saveToken = userToken => {
        sessionStorage.setItem('token', userToken);
        // console.log("token put to storage = " + userToken)
        setToken(userToken);
    };

    return {
        currentToken: token,
        setToken: saveToken
    }
}

export default UseToken;
