import ContentLoader from "react-content-loader";
import React from "react"

export default function CustomContentForLoadAndError(loaded, error, title, content) {
    if (error !== null) {
        return (
            <>{error}</>
        )
    }
    else if (loaded === false){
        if(content){
            return content;
        }
        return (
                    <ContentLoader
                        speed={2}
                        // width={400}
                        // height={160}
                        viewBox="0 0 400 160"
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb">
                        <rect x="0" y="8" rx="3" ry="3" width="88" height="6" />
                        <rect x="0" y="26" rx="3" ry="3" width="52" height="6" />
                        <rect x="0" y="100" rx="3" ry="3" width="410" height="6" />
                        <rect x="0" y="72" rx="3" ry="3" width="380" height="6" />
                        <rect x="0" y="88" rx="3" ry="3" width="178" height="6" />
                    </ContentLoader>
        )
    }

    return null;
}
