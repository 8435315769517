import {styled} from "@mui/styles";
import {Box} from "@mui/system";


export const InlineBox = styled(Box)(() => ({
    display: "inline-flex"
}));

export const ColumnBox = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column"
}));


export const CenteredBox = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
}));
